<template>
  <b-card title="E-Learning Participant List">
    <b-col cols="12 row text-left" class="p-0 mt-1">
      <b-col cols="col-2 md-2 sm-6 ml-1 mb-2">
        <b-button
          variant="success"
          class="btn waves-effect waves-float waves-light btn-success"
          v-b-modal.form-export-modal
        >
          Export E-Learning Participants
        </b-button>
      </b-col>
    </b-col>

    <b-row class="justify-content-between">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="start-date">Date:</label>
          <flat-pickr
            id="start-date"
            class="form-control"
            v-model="dateRange"
            :config="{
              dateFormat: 'Y-m-d',
              mode: 'range',
            }"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :participants="participants"
      :is-loading="isLoading"
      :get-data="getData"
      :current-page="currentPage"
      :showId="showId"
      :show-item="showItem"
      :modalData="modalData"
    />

    <Modal
      :get-history="getHistory"
      :get-columns="getColumns"
      :history="histories"
      :columns="columns"
      :startDate="filter.date_range.start_date"
      :endDate="filter.date_range.end_date"
    />
  </b-card>
</template>

<script>
import Table from "@/components/e-learning/TableListParticipant.vue";
import Modal from "@/components/e-learning/ModalParticipant.vue";
import flatPickr from "vue-flatpickr-component";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    flatPickr,
    Modal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
    dateRange: {
      handler: _.debounce(function () {
        if (this.dateRange) {
          const dates = this.dateRange.split(" to ");
          if (dates.length === 2) {
            this.filter.date_range.start_date = dates[0];
            this.filter.date_range.end_date = dates[1];
          }
        }
      }, 300),
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      participants: [],
      link: "",
      isLoadingExport: false,
      formPayload: {
        name: "",
      },
      showId: null,
      filter: {
        date_range: {
          start_date: "",
          end_date: "",
        },
      },
      dateRange: null,
      validations: "",
      modalData: {},
      histories: [],
      columns: [],
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      const queryParams = { ...this.filter, page };

      delete queryParams.date_range;

      if (this.filter.date_range.start_date && this.filter.date_range.end_date) 
      { 
        queryParams["date_range[start_date]"] =this.filter.date_range.start_date;
        queryParams["date_range[end_date]"] = this.filter.date_range.end_date;
      }

      this.$http
        .get(
          `/api/v1/admin/e-learning/${this.$route.params.uuid}/participants`,
          {
            params: queryParams,
          }
        )
        .then((response) => {
          this.result = response.data.data.meta;
          this.participants = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getHistory() {
      this.$http
        .get(
          `/api/v1/admin/export/history/e-learning-participants?filter_key=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.histories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColumns() {
      this.$http
        .get("/api/v1/admin/export/column?type=e-learning-participants")
        .then((response) => {
          this.columns = response.data.data.shift().columns;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showItem(item) {
      this.showId = item.uuid;
      // this.formPayload = {
      //     name: item.name,
      // };

      // Call the API to fetch the data for the modal
      this.$http
        .get(
          `/api/v1/admin/e-learning/${this.$route.params.uuid}/participants/${item.uuid}`
        )
        .then((response) => {
          this.modalData = response.data.data;
          this.$bvModal.show("participant-history-modal");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
