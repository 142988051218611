<template>
  <div>
    <b-table striped hover :items="participantList" :fields="fields" :busy="isLoadingComponent" show-empty responsive>
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(is_can_continue)="{ item }">
        <b-badge variant="light-success" v-if="item.is_can_continue === true">
          Yes
        </b-badge>
        <b-badge variant="light-danger" v-else> No </b-badge>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-button variant="primary" size="sm" @click="showItem(item)">
          <feather-icon icon="PeopleIcon" class="mr-75" />
          Lihat Detail
        </b-button>
      </template>
    </b-table>

    <b-modal id="participant-history-modal" centered :title="'Detail Peserta'" size="lg" no-close-on-backdrop hide-footer>

      <!-- {{ modalDataDetail.user_grade_histories }} -->

      <template v-if="modalDataDetail">
        <h5>Assessment History</h5>
        <table class="table b-table table-striped table-hover table-sm table-bordered">

          <tr>
            <th>Grade</th>
            <th>Tipe</th>
            <th>History (Tanggal - Nilai - Status)</th>
          </tr>

          <tr v-for="(item, index) in modalDataDetail.user_grade_histories" :key="index">
            <td>{{ item.grade }}</td>
            <td>{{ item.type }}</td>
            <td>
              <ul v-if="item.repeat_histories">
                <li v-for="(item, index2) in item.repeat_histories" :key="index2">
                  {{ moment(item.time).locale('id').format('DD MMMM YYYY') }} - {{ item.grade }} - <b-badge pill :variant="item.is_passed ? 'light-success' : 'light-danger'">{{ item.is_passed ? 'Lulus' : 'Tidak Lulus' }}</b-badge>
                </li>
              </ul>
            </td>
          </tr>
          
        </table>

        <h5 class="mt-2">Submitted Files</h5>
        <table class="table b-table table-striped table-hover table-sm table-bordered">
          <tr>
            <th>No</th>
            <th>Question</th>
            <th>Template file (ppt/pptx)</th>
            <th>File Upload</th>
          </tr>

          <tr v-for="(item, index) in modalDataDetail.user_task_files" :key="index">
            <td width="20" >{{  index + 1 }}</td>
            <td>{{  item.question.text }}</td>
            <td>
              <a v-if="item.question.template" :href="item.question.template" target="_blank" rel="noopener noreferrer">Download</a>
            </td>
            <td>
              <a :href="item.file" target="_blank" rel="noopener noreferrer">Download</a>
            </td>
          </tr>

          </table>

        
      </template>




    </b-modal>

    <div v-if="resultData.itemsPerPage > 0" class="mt-3 d-flex justify-content-between align-items-center">
      <small>Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small>
      <b-pagination class="justify-content-end" v-model="currentPageList" :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage" aria-controls="my-table" @change="getData"></b-pagination>
    </div>
  </div>
</template>
  
<script>
import moment from "moment";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  BFormFile,
  BFormRadioGroup,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    vSelect,
    quillEditor,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    participants: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    showId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    showItem: {
      type: Function,
    },
    modalData: {
      type: "",
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    participants(value) {
      this.participantList = value;
    },
    formPayload(value) {
      this.formPayloadPost = value;
    },
    validations(value) {
      this.validationsForm = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
    showId(value) {
      this.showIdModal = value;
    },
    modalData(value) {
      this.modalDataDetail = value;
    },
  },
  data() {
    return {
      moment,
      required,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      participantList: [],
      showIdModal: null,
      formPayloadPost: {
        name: "",
      },
      file_type: "",
      file_url: "",
      file: "fileContent",
      validationsForm: "",
      category: [],
      categorySelected: [],
      fields: [
        { key: "no" },
        { key: "name" },
        { key: "email" },
        { key: "grade" },
        { key: "created_at" },
        { key: "status" },
        { key: "is_can_continue", 'label': 'Pass the to next stage' },
        { key: "actions" },
      ],
      //   filter: {
      //     order_type: "desc",
      //   },
      modalDataDetail: null,
    };
  },
  computed: {
    rows() {
      return this.participantList.length;
    },
  },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.ql-editor.ql-blank {
  height: 400px;
}

.media_file {
  width: 400px;
  height: 300px;
}

.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
</style>
  